import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { P, TypographyDemo, TypographyMixinsDemo } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "типографика"
    }}>{`Типографика`}</h1>
    <p>{`В дизайн системе ВШЭ используется фирменный шрифт `}<strong parentName="p">{`HSE Sans`}</strong>{` —
современный гротеск со своим характером. Большая часть интерфейсов — это
текст, поэтому очень важно обращать внимание на набор, а также соблюдать
правила пунктуации и орфографии.`}</p>
    <h2 {...{
      "id": "миксины-и-классы"
    }}>{`Миксины и классы`}</h2>
    <p>{`Для стилизации текста в дизайн-системе используются SCSS-миксины и соответствующие им классы.
Для использования миксинов, как и для использования токенов, достаточно
импортировать файл `}<inlineCode parentName="p">{`base.scss`}</inlineCode>{` из пакета `}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`@import '~@hse-design/core/src/base.scss';

.text {
    @include text_heading_h1;
}
`}</code></pre>
    <p>{`Для использования текстовых классов, назначьте их нужному элементу:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class="hse-Text_heading-h1">...</p>
`}</code></pre>
    <p>{`Ниже приведены демонстрационные примеры для каждого миксина с указанием его
имени в скобках.
Имена текстовых классов соответствуют именам миксинов с заменой префикса
`}<inlineCode parentName="p">{`text`}</inlineCode>{` на `}<inlineCode parentName="p">{`hse-Text`}</inlineCode>{` (см. пример выше).`}</p>
    <TypographyMixinsDemo mdxType="TypographyMixinsDemo" />
    <h2 {...{
      "id": "заголовки"
    }}>{`Заголовки`}</h2>
    <p>{`Для того, чтобы страницы выглядели гармонично, заголовок и наборный текст
должны отличаться друг от друга на пару пунктов. Например, с `}<inlineCode parentName="p">{`text_heading_h2`}</inlineCode>{`
лучше всего использовать `}<inlineCode parentName="p">{`text_paragraph_large`}</inlineCode>{` или в крайнем случае
`}<inlineCode parentName="p">{`text_lead_large`}</inlineCode>{`. При этом нужно понимать цель: в промо-материалах лучше
смотрится большой контраст между набором и заголовком, в интерфейсах такой
подход отвлекает от главного, поэтому градус контрастности лучше снижать.`}</p>
    <p>{`Для лидов в промо-материалах мы добавили начертание HSE Slab —
`}<inlineCode parentName="p">{`text_lead_large`}</inlineCode>{` и `}<inlineCode parentName="p">{`text_lead_small`}</inlineCode>{`. Не рекомендуется использовать его в
интерфейсах и личных кабинетах.`}</p>
    <h2 {...{
      "id": "наборный-текст-подписи-подзаголовки"
    }}>{`Наборный текст, подписи, подзаголовки`}</h2>
    <p>{`Оптимальный размер наборного текста — 18px (`}<inlineCode parentName="p">{`text_paragraph_large`}</inlineCode>{`). Также в
дизайн-системе предусмотрен более мелкий стиль текста — 16px
(`}<inlineCode parentName="p">{`text_paragraph_small`}</inlineCode>{`). Его нужно использовать только в крайнем случае.`}</p>
    <p>{`Жирность — важная типографская переменная, которая помогает акцентировать
внимание и позволяет выстраивать иерархию. Жирное начертание имеет больший
акцент, чем нормальное того же размера. Рекомендуется использовать его для
заголовков, подзаголовоков, лидов и выделения. При этом оно совсем не подходит
для длинного текста.`}</p>
    <p>{`Цвет. Нужно внимательно подходить к выбору цвета для основного текста.
Рекомендуется использовать нейтральный цвет для наборного текста, системные
цвета для ошибок и сообщений об успехе, а также брендовый синий для ссылок и
действий.`}</p>
    <h2 {...{
      "id": "длина-строки"
    }}>{`Длина строки`}</h2>
    <p>{`Люди читают с экрана компьютера очень избирательно. Необходимо выбирать
удобную длину строки, чтобы текст не был слишком коротким или слишком длинным.
Для оптимальной читабельности рекомендуется выбирать длину строки от 40 до 80
символов, включая пробелы.`}</p>
    <h2 {...{
      "id": "токены"
    }}>{`Токены`}</h2>
    <p>{`Любой текст на странице можно стилизовать с помощью миксинов или текстовых классов, но при
необходимости можно использовать отдельные токены текстовых стилей. Для
каждого токена ниже приведены имя соответствующей SCSS-переменной и ее
значение.`}</p>
    <h3 {...{
      "id": "название-шрифта-fontfamily"
    }}>{`Название шрифта (fontFamily)`}</h3>
    <p>{`При использовании токенов этой группы в значении css-свойства `}<inlineCode parentName="p">{`font-family`}</inlineCode>{` указывайте через запятую
набор шрифтов по-умолчанию в качестве фолбека:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.text {
  font-family: $font-family-proxima-nova, $font-family-default;
}
`}</code></pre>
    <TypographyDemo tokenName="fontFamily" mdxType="TypographyDemo" />
    <p>{`Также вы можете использовать набор системных моноширинных шрифтов:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`code {
  font-family: $font-family-mono;
}
`}</code></pre>
    <h3 {...{
      "id": "начертание-fontweight"
    }}>{`Начертание (fontWeight)`}</h3>
    <TypographyDemo tokenName="fontWeight" mdxType="TypographyDemo" />
    <h3 {...{
      "id": "высота-строки-lineheight"
    }}>{`Высота строки (lineHeight)`}</h3>
    <TypographyDemo tokenName="lineHeight" mdxType="TypographyDemo" />
    <h3 {...{
      "id": "размер-fontsize"
    }}>{`Размер (fontSize)`}</h3>
    <TypographyDemo tokenName="fontSize" mdxType="TypographyDemo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      